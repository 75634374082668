const OVERLAP_X = 0.6
const OVERLAP_Y = 0.3
const IMAGE_GB_PER_MPX = 0.000318
const ORTHO_GB_PER_MXP = IMAGE_GB_PER_MPX

export default function Calculate(inputData) {
    return new Calculator(inputData).Calculate()
}

class Calculator {
    constructor(inputData) {
        this.#inputData = inputData
    }

    Calculate() {
        const areaUnit = this.#inputData.gsd * this.#inputData.gsd * 0.0001
        const area = this.#inputData.area / areaUnit
        const count = this._CalculateImagesCount(area, true)
        const rawMpx = count * this.#inputData.imageWidth * this.#inputData.imageHeight / 1e6
        const orthoMpx = area / 1e6
        return {
            orthomosaic: {
                mpx: orthoMpx,
                gb: ORTHO_GB_PER_MXP * orthoMpx
            },
            rawImagery: {
                mpx: rawMpx,
                gb: IMAGE_GB_PER_MPX * rawMpx,
                count
            },
            total: {
                mpx: orthoMpx + rawMpx,
                gb: ORTHO_GB_PER_MXP * orthoMpx + IMAGE_GB_PER_MPX * rawMpx
            }
        }
    }

    _CalculateImagesCount(area, isInitial) {
        /* Approximate by square. */
        const size = Math.sqrt(area)
        const x = this._CalculateSide(size, this.#inputData.imageWidth, OVERLAP_X, isInitial)
        const y = this._CalculateSide(size, this.#inputData.imageHeight, OVERLAP_Y, isInitial)
        const count = x.count * y.count
        const remainedArea = area - (size - x.remainder) * (size - y.remainder)
        if (remainedArea > 0) {
            return count + this._CalculateImagesCount(remainedArea, false)
        }
        return count
    }

    /** @return {{ count, remainder }}
     */
    _CalculateSide(size, imageSize, overlap, isInitial) {
        const step = imageSize * (1.0 - overlap)
        const initialSize = isInitial ? imageSize : step
        size -= initialSize
        if (size <= 0) {
            return { count: 1, remainder: 0 }
        }
        const count = Math.floor(size / step)
        return {
            count: count + 1,
            remainder: size - count * step
        }
    }

    #inputData
}