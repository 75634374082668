export const AreaUnits = {
    HA: {
        id: "HA",
        shortLabel: "ha",
        factor: 10000
    },
    M2: {
        id: "M2",
        shortLabel: "sq. m",
        factor: 1
    },
    AC: {
        id: "AC",
        shortLabel: "acres",
        factor: 4046.86
    },
    FT2: {
        id: "FT2",
        shortLabel: "sq. ft",
        factor: 0.092903
    }
}
