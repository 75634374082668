import config from "@/config"

function FilterId(s) {
    let result = ""
    for (let c of s) {
        if ((c >= '0' && c <= '9') ||
            (c >= 'a' && c <= 'z') ||
            (c >= 'A' && c <= 'Z') ||
            c === '_' || c === '-' || c === '.') {

            result += c
        } else {
            result += '_'
        }
    }
    return result
}

function CreateCameraId(cam) {
    let id = ""
    if (cam.hasOwnProperty("vendor")) {
        id += FilterId(cam.vendor)
    }
    if (cam.hasOwnProperty("model")) {
        if (id !== "") {
            id += "-"
        }
        id += FilterId(cam.model)
    }
    if (cam.hasOwnProperty("name")) {
        if (id !== "") {
            id += "-"
        }
        id += FilterId(cam.name)
    }
    if (cam.hasOwnProperty("imageWidth")) {
        if (id !== "") {
            id += "-"
        }
        id += cam.imageWidth
    }
    if (cam.hasOwnProperty("imageHeight")) {
        if (id !== "") {
            id += "-"
        }
        id += cam.imageHeight
    }
    return id
}

function CreateCameraName(cam) {
    if (cam.hasOwnProperty("name")) {
        return cam.name
    }
    let name = ""
    if (cam.hasOwnProperty("vendor")) {
        name += cam.vendor
    }
    if (cam.hasOwnProperty("model")) {
        if (name !== "") {
            name += "/"
        }
        name += cam.model
    }
    return name
}

export default {
    namespaced: true,

    state: {
        cameras: null
    },

    mutations: {
        setCameras(state, cameras)
        {
            state.cameras = cameras
        }
    },

    actions: {
        async fetchDb({ commit }) {
            const resp = await fetch(config.camerasDbUrl)
            const rawList = await resp.json()
            const result = new Map()
            for (let rawCam of rawList) {
                const id = CreateCameraId(rawCam)
                rawCam.id = id
                if (!rawCam.hasOwnProperty("name")) {
                    rawCam.name = CreateCameraName(rawCam)
                }
                result.set(id, rawCam)
            }
            commit("setCameras", result)
        }
    }
}
