<template>
<div class="column">
    <q-input v-model="coordinatesSearch" dense filled label="Search coordinates"
             class="q-mb-xs" @keydown.enter="_OnSearch"
             bottom-slots :error="searchError !== null" :error-message="searchError"
             @input="searchError = null">
        <template v-slot:append>
            <q-btn round dense flat icon="search" @click="_OnSearch"/>
        </template>
    </q-input>
    <div ref="map" class="map"></div>
    <div class="hint q-my-sm text-italic">
        Click on any point to stop adding points. Click on any point to remove it after that. Use
        reset button to remove polygon and start over.
    </div>
</div>
</template>

<script>
/**
 * Events:
 * areaUpdated(area)
 *      area: new enclosed area, sq.m. May be null if no closed polygon.
 * polygonChanged(points)
 *      points: new polygon point coordinates, array of L.LatLng
 */
import L from "leaflet"
import "leaflet-editable/src/Leaflet.Editable"
import "leaflet.path.drag/src/Path.Drag"
import "leaflet-draw/dist/leaflet.draw-src"
import {IsEmpty, ParseLatLng} from "@/common/utils";

const COORDINATES_SEARCH_ZOOM = 15

export default {
    name: "Map",

    data() {
        return {
            coordinatesSearch: null,
            searchError: null
        }
    },

    methods: {
        InvalidateSize() {
            this.map.invalidateSize()
        },

        SetPoints(points) {
            this.polygon.remove()
            this.polygon = L.polygon(points, {editable: true}).addTo(this.map)
            this.map.fitBounds(L.latLngBounds(points), {maxZoom: 23})
            this.map.editTools.commitDrawing()
        },

        _OnEditing() {
            const points = this.polygon.getLatLngs()[0]
            this.$emit("polygonChanged", points)
            if (points.length < 3) {
                return
            }
            const area = L.GeometryUtil.geodesicArea(points)
            this.$emit("areaUpdated", area)
        },

        _OnSearch() {
            if (IsEmpty(this.coordinatesSearch)) {
                return
            }
            let coords
            try {
                coords = ParseLatLng(this.coordinatesSearch)
            } catch (e) {
                this.searchError = e.message
                return
            }
            this.coordinatesSearch = null
            this.map.setView(coords, COORDINATES_SEARCH_ZOOM, {animate: true})
        }
    },

    mounted() {
        this.map = L.map(this.$refs.map, {
            zoomControl: true,
            zoom: 1,
            maxZoom: 23,
            minZoom: 0,
            editable: true
        })
        this.osmLayer = L.tileLayer(
            "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
            {
                attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                minZoom: 0,
                maxNativeZoom: 19,
                maxZoom: 23
            }
        )
        this.osmLayer.addTo(this.map)
        this.map.setView([0, 0], 0)

        const component = this
        const ResetPolygonControl = L.Control.extend({
            options: {
                position: "topleft"
            },

            onAdd: function (map) {
                const container = L.DomUtil.create("div", "leaflet-control leaflet-bar")
                const link = L.DomUtil.create("a", "", container)
                link.href = "#"
                link.title = "Reset polygon"
                link.innerHTML = '<i class="material-icons q-icon notranslate" style="font-size: 1rem;">cached</i>'
                L.DomEvent.on(link, "click", L.DomEvent.stop)
                    .on(link, "click", () => {
                        component.polygon.remove()
                        component.$emit("polygonChanged", [])
                        component.$emit("areaUpdated", null)
                        component.polygon = map.editTools.startPolygon()
                    })
                container.style.display = "block"
                map.editTools.on("editable:enabled", () => {
                    container.style.display = "none"
                })
                map.editTools.on("editable:disable", () => {
                    container.style.display = "block"
                })
                return container
            }
        })
        this.map.addControl(new ResetPolygonControl())

        this.map.on("editable:editing", this._OnEditing)
        this.map.on("editable:dragend", this._OnEditing)
        this.polygon = this.map.editTools.startPolygon()
    }
}
</script>

<style scoped lang="less">

.map {
    min-height: 300px;
    max-width: 500px;
}

.hint {
    max-width: 500px;
    line-height: 1.2;
}

</style>