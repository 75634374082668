<template>
<q-layout view="hHh lpr fff">
    <q-page-container>
        <router-view />
    </q-page-container>
</q-layout>
</template>

<script>
import {mapActions} from "vuex";

export default {
    methods: {
        ...mapActions("cameras", ["fetchDb"])
    },

    created() {
        this.fetchDb()
    }
}
</script>
