import {PostRequest} from "@/common/utils";

export default {
    namespaced: true,

    actions: {
        /** @param data {{
         *      to,
         *      from,
         *      url
         *  }}
         */
        async sendMail({}, data) {
            await PostRequest("/api/SendMail", data)
        }
    }
}