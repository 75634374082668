import L from "leaflet"

export function IsEmpty(val) {
    return val === null || (typeof val == "string" && val.trim() === "")
}

export function ValidateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

/** Parse comma-separated decimal latitude/longitude pair.
 * @return L.LatLng
 */
export function ParseLatLng(s) {
    if (IsEmpty(s)) {
        throw new Error("Empty value")
    }
    const parts = s.split(',')
    if (parts.length !== 2) {
        throw new Error("Comma-separated latitude and longitude decimal value expected: " + s)
    }

    if (IsEmpty(parts[0])) {
        throw new Error("Latitude empty")
    }
    const lat = Number(parts[0])
    if (isNaN(lat)) {
        throw new Error("Bad latitude value: " + lat)
            }
    if (lat > 90 || lat <-90) {
        throw new Error("Latitude value out of range: " + lat)
    }

    if (IsEmpty(parts[1])) {
        throw new Error("Longitude empty")
    }
    const lng = Number(parts[1])
    if (isNaN(lng)) {
        throw new Error("Bad longitude value: " + lng)
    }
    /* Longitude can be safely wrapped so do not check range. */

    return L.latLng(lat, lng)
}

export class PostRequestError extends Error {
    constructor(message, json) {
        super(message)
        if (json !== null) {
            this.responseJson = json
        }
    }
}

export async function PostRequest(url, data) {
    let params = {
        method: data !== undefined ? "POST" : "GET"
    }
    if (data !== undefined) {
        params.body = JSON.stringify(data)
        params.headers = {
            "Content-Type": "application/json; charset=utf-8"
        }
    }
    let response = await fetch(url, params)

    if (!response.ok) {
        let json = null
        let type = response.headers.get("Content-Type")
        if (type !== null && type.startsWith("application/json")) {
            try {
                json = await response.json()
            } catch {}
        }
        throw new PostRequestError(`Bad response: ${response.status} ${response.statusText}`, json)
    }

    if (response.status === 204) {
        return null
    }

    return await response.json()
}