<template>
<div class="column">

    <q-input v-model="to" dense label="Recipient e-mail" autofocus bottom-slots
             :error="toError !== null" :error-message="toError"
             @keydown.enter.stop.prevent="_OnSend">
        <template v-slot:prepend><span class="text-body1">To:</span></template>
    </q-input>
    <q-input v-model="from" dense label="Optional sender name"
             @keydown.enter.stop.prevent="_OnSend">
        <template v-slot:prepend><span class="text-body1">From:</span></template>
    </q-input>

    <div class="q-my-md">
        Hello,<br/>
        {{ fromName }} shared
        <a href="#" target="_blank" @click.prevent="_OnSharedLinkClick">Atlas photogrammetry calculations</a>
        with you.<br/><br/>
        Best regards,<br/>
        <a href="https://atlas.ugcs.com">Atlas Team</a>
    </div>

    <div class="q-mb-md">
        <q-checkbox v-model="policyAccepted">
            By checking this box I consent that I have read and agree to
            <a href="https://atlas.ugcs.com/privacypolicy" @click.stop>Privacy policy</a>
        </q-checkbox>
    </div>

    <div class="row justify-end">
        <q-btn color="primary" icon="send" label="Send" class="col-auto" @click="_OnSend"
               :loading="sendInProgress" :disable="!policyAccepted"/>
    </div>
</div>
</template>

<script>
/**
 * Events:
 *  sent - when message sent
 */
import {IsEmpty, ValidateEmail} from "@/common/utils"
import {mapActions} from "vuex"

export default {
    name: "SharingForm",

    props: {
        data: {}
    },

    data() {
        return {
            to: null,
            from: null,
            sendAttempted: false,
            sendInProgress: false,
            policyAccepted: false
        }
    },

    computed: {
        fromName() {
            return IsEmpty(this.from) ? "Someone" : this.from
        },

        toError() {
            if (!this.sendAttempted) {
                return null
            }
            if (IsEmpty(this.to)) {
                return "Should be specified"
            }
            if (!ValidateEmail(this.to)) {
                return "Invalid e-mail address"
            }

            return null
        },

        sharedLink() {
            let query = ""
            for (const [key, value] of Object.entries(this.data)) {
                if (value !== null) {
                    if (query !== "") {
                        query += "&"
                    }
                    query += this._ReprLinkParam(key, value)
                }
            }

            return `/#${this.$route.path}?${query}`
        }
    },

    methods: {
        ...mapActions("mail", ["sendMail"]),

        async _OnSend() {
            this.sendAttempted = true
            if (this.sendInProgress || this.toError !== null || !this.policyAccepted) {
                return
            }
            this.sendInProgress = true
            const data = {
                to: this.to,
                from: this.from,
                url: this.sharedLink
            }
            try {
                await this.sendMail(data)
            } catch (e) {
                console.error(e)
                let msg = "E-mail sending failed"
                this.$q.notify({
                    message: "E-mail sending failed",
                    icon: "error",
                    color: "negative"
                })
                return
            } finally {
                this.sendInProgress = false
            }
            this.$emit("sent", data)
        },

        _OnSharedLinkClick() {
            /* Use this to prevent simple copying link target. However it still can be copied from
             * new tab address bar but it is reasonable level of inconvenience to force specifying
             * e-mail (too paranoiac persons will use temporal mail anyway).
             */
            window.open(this.sharedLink)
        },

        _ReprLinkParam(name, val) {

            function GetValueRepr(val) {
                if (typeof val === "object" && val.hasOwnProperty("id")) {
                    return val.id
                } else {
                    return val
                }
            }

            if (Array.isArray(val)) {
                let repr = ""
                for (let item of val) {
                    if (Array.isArray(item)) {
                        throw new Error("Nested arrays not supported")
                    }
                    if (repr !== "") {
                        repr += "&"
                    }
                    repr += `${name}=${GetValueRepr(item)}`
                }
                return repr

            } else {
                return `${name}=${GetValueRepr(val)}`
            }
        }
    }
}

</script>