import Vue from "vue"
import VueRouter from "vue-router"
import App from "@/App"
import "leaflet/dist/leaflet.css"

import Vuex from "vuex"
Vue.use(Vuex)

import storeConfig from "@/store/index"
const store = new Vuex.Store(storeConfig)

Vue.use(VueRouter)
import routes from "@/Routes"
const router = new VueRouter({
    mode: "hash",
    routes
})

import quasarConfig from "@/Quasar"
Vue.use(...quasarConfig)

import "@/assets/styles/global.less"

new Vue({
    el: "#app",
    router,
    store,
    render: h => h(App)
})
