<template>
    <q-page padding class="row justify-center items-start">
        <Calculator class="auto-col"/>
    </q-page>
</template>

<script>
import Calculator from "@/components/Calculator"

export default {
    name: "LandingPage",
    components: {Calculator}
}
</script>
