<template>
<q-card>
    <q-card-section class="text-h5 text-center">Megapixel calculator</q-card-section>
    <q-card-section class="row">
        <q-card-section class="col-auto q-py-none">
            <q-card-section class="column q-pt-none">
                <q-expansion-item v-model="isMapActive" dense
                                  @after-show="_OnMapShown" expand-icon-toggle
                                  switch-toggle-side
                                  expand-icon-class="hidden">
                    <template v-slot:header>
                        <q-radio v-model="areaInput" val="manual" label="Enter area size" />
                        <q-radio v-model="areaInput" val="map" label="Draw polygon on map" />
                    </template>
                    <Map ref="map" @areaUpdated="_OnMapAreaUpdated"
                         @polygonChanged="_OnPolygonChanged"/>
                </q-expansion-item>
                <div class="row items-baseline q-my-sm">
                    <q-input filled dense v-model="displayedArea" label="Area"
                             class="q-mr-sm input-field"
                             bottom-slots :error="areaError !== null" :error-message="areaError"
                             @blur="areaBlurred=true"
                             hint="Target area size"
                             :disable="isMapActive"/>
                    <q-select v-model="units" class="col-auto" filled dense
                              style="min-width: 100px;"
                              :options="unitsList"
                              :option-value="opt => opt"
                              :option-label="opt => opt.shortLabel"
                              label="Units" />
                </div>
                <div class="row items-baseline q-my-sm">
                    <q-input filled dense v-model="gsd" class="input-field"
                             label="GSD" hint="Ground sample distance, cm"
                             bottom-slots :error="gsdError !== null" :error-message="gsdError"
                             @blur="gsdBlurred=true" />
                </div>
                <div class="row items-baseline q-my-md column">
                    <div class="row items-center q-my-xs">
                        <q-select v-model="cameraProfile" class="q-mb-sm input-field"
                                  filled dense
                                  style="min-width: 200px;"
                                  :options="filteredCameraProfiles"
                                  option-label="name"
                                  label="Camera"
                                  :disable="customCamera || cameraProfilesList === null"
                                  :loading="cameraProfilesList === null"
                                  use-input
                                  @filter="_CameraProfileFilter"
                                  input-debounce="0"
                                  hide-selected
                                  fill-input>
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                        Nothing found
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <q-toggle v-model="customCamera" label="Custom" class="col-auto"/>
                    </div>
                    <div class="row items-baseline q-my-xs">
                        <q-input filled dense v-model="imageWidth" label="Width"
                                 class="input-field"
                                 hint="Image width, pixels" :disable="!customCamera"
                                 bottom-slots :error="widthError !== null" :error-message="widthError"
                                 @blur="widthBlurred=true"/>
                    </div>
                    <div class="row items-baseline q-my-xs">
                        <q-input filled dense v-model="imageHeight" label="Height"
                                 class="input-field"
                                 hint="Image height, pixels" :disable="!customCamera"
                                 bottom-slots :error="heightError !== null" :error-message="heightError"
                                 @blur="heightBlurred=true"/>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>

        <q-card-section class="col-auto column q-mx-lg q-pt-xs" style="min-width: 300px;">
            <template v-if="result !== null">
                <q-tree default-expand-all :nodes="treeResult" node-key="label" />
                <q-btn class="q-mt-md" color="primary" text-color="black" icon="mail_outline"
                       label="Share" @click="_OnShare"/>
            </template>
        </q-card-section>
    </q-card-section>

    <q-dialog v-model="shareDialog" persistent>
        <q-card style="max-width: 600px;">
            <q-card-section class="row items-center q-pb-none">
                <div class="text-h6"><q-icon name="mail"/> E-mail link to the calculation</div>
                <q-space />
                <q-btn icon="close" flat round dense v-close-popup />
            </q-card-section>

            <q-card-section>
                <sharing-form :data="shareableData" @sent="_OnSent"/>
            </q-card-section>
        </q-card>
    </q-dialog>
</q-card>
</template>

<script>

import {AreaUnits} from "@/common/units"
import {IsEmpty, ParseLatLng} from "@/common/utils"
import Calculate from "@/math/Calculator"
import SharingForm from "@/components/SharingForm"
import {mapState} from "vuex"
import Map from "@/components/Map";

export default {
    name: "Calculator",
    components: {Map, SharingForm},
    data() {
        return {
            manualArea: null,
            areaBlurred: false,
            units: AreaUnits.HA,
            unitsList: Object.values(AreaUnits),
            gsd: null,
            gsdBlurred: false,
            imageWidth: null,
            imageHeight: null,
            widthBlurred: false,
            heightBlurred: false,
            cameraProfile: null,
            customCamera: false,
            shareDialog: false,
            mapArea: null,
            areaInput: "manual",
            polygonPoints: [],
            presetPolygonPoints: null,
            filteredCameraProfiles: null
        }
    },

    watch: {
        customCamera(val) {
            if (val) {
                this.cameraProfile = null
            } else {
                this.imageWidth = null
                this.imageHeight = null
            }
        },

        cameraProfile(val) {
            if (val !== null) {
                this.imageWidth = val.imageWidth
                this.imageHeight = val.imageHeight
            }
        },

        cameraProfiles(val) {
            const q = this.$route.query
            if (val !== null && !this.customCamera && "cameraProfile" in q) {
                this.cameraProfile = val.get(q.cameraProfile) || null
            }
        }
    },

    computed: {
        ...mapState("cameras", { cameraProfiles: "cameras" }),

        cameraProfilesList() {
            const map = this.cameraProfiles
            if (map === null) {
                return null
            }
            return [...map.values()].sort((p1, p2) => p1.name.localeCompare(p2.name))
        },

        areaError() {
            if (!this.areaBlurred || this.isMapActive) {
                return null
            }
            return this._PositiveNumberValidator(this.manualArea)
        },

        gsdError() {
            if (!this.gsdBlurred) {
                return null
            }
            return this._PositiveNumberValidator(this.gsd)
        },

        widthError() {
            if (!this.widthBlurred || !this.customCamera) {
                return null
            }
            return this._PositiveNumberValidator(this.imageWidth)
        },

        heightError() {
            if (!this.heightBlurred || !this.customCamera) {
                return null
            }
            return this._PositiveNumberValidator(this.imageHeight)
        },

        isMapActive: {
            get() {
                return this.areaInput === "map"
            },

            set(val) {
                this.areaInput = val ? "map" : "manual"
            }
        },

        areaSpecified() {
            return (this.isMapActive && this.mapArea !== null) ||
                   (!this.isMapActive && !IsEmpty(this.manualArea))
        },

        area() {
            if (this.isMapActive) {
                return this.mapArea ?? NaN
            }
            if (IsEmpty(this.manualArea)) {
                return NaN
            }
            const val = Number(this.manualArea)
            if (isNaN(val)) {
                return NaN
            }
            return val * this.units.factor
        },

        displayedArea: {
            get() {
                if (this.isMapActive) {
                    if (this.mapArea === null) {
                        return ""
                    }
                    return this._FormatNumber(this.mapArea / this.units.factor, "area")
                }
                return this.manualArea
            },

            set(val) {
                this.manualArea = val
            }
        },

        inputData() {
            if (!this.areaSpecified || IsEmpty(this.gsd) ||
                IsEmpty(this.imageWidth) || IsEmpty(this.imageHeight)) {

                return null
            }
            const data = {
                area: this.area,
                gsd: Number(this.gsd),
                imageWidth: Number(this.imageWidth),
                imageHeight: Number(this.imageHeight)
            }
            if (isNaN(data.area) || isNaN(data.gsd) || isNaN(data.imageWidth) ||
                isNaN(data.imageHeight) ||
                data.area <= 0 || data.gsd <= 0 || data.imageWidth <= 0 || data.imageHeight <= 0) {

                return null
            }
            return data
        },

        shareableData() {
            if (this.inputData === null) {
                return null
            }
            const data = {
                area: this.manualArea,
                units: this.units,
                gsd: this.gsd,
                cameraProfile: this.cameraProfile,
                customCamera: this.customCamera,
                imageWidth: this.imageWidth,
                imageHeight: this.imageHeight
            }
            if (this.isMapActive) {
                data.mapPoints = this.polygonPoints.map(pt => `${pt.lat},${pt.lng}`)
            }
            return data
        },

        result() {
            const input = this.inputData
            if (input === null) {
                return null
            }
            return Calculate(input)
        },

        treeResult() {
            const result = this.result
            if (result === null) {
                return null
            }
            return [{
                label: "Orthomosaic",
                children: [
                    { label: `${this._FormatNumber(result.orthomosaic.mpx, "mpx")} Mpx`},
                    { label: `${this._FormatNumber(result.orthomosaic.gb, "gb")} GB`}
                ]
            }, {
                label: "Raw imagery",
                children: [
                    { label: `${this._FormatNumber(result.rawImagery.count, "images")} images`},
                    { label: `${this._FormatNumber(result.rawImagery.mpx, "mpx")} Mpx`},
                    { label: `${this._FormatNumber(result.rawImagery.gb, "gb")} GB`}
                ]
            }, {
                label: "Total",
                children: [
                    { label: `${this._FormatNumber(result.total.mpx, "mpx")} Mpx`},
                    { label: `${this._FormatNumber(result.total.gb, "gb")} GB`}
                ]
            }]
        }
    },

    methods: {
        _PositiveNumberValidator(val) {
            if (IsEmpty(val)) {
                return "Should be specified"
            }
            const num = Number(val)
            if (isNaN(num)) {
                return "Should be valid number"
            }
            if (num <= 0) {
                return "Should be positive"
            }
            return null
        },

        /**
         * @param val {Number}
         * @param type "images", "mpx", "gb", "area"
         */
        _FormatNumber(val, type) {
            let precision
            let groupDelimiter = " "
            switch (type) {
                case "mpx":
                case "images":
                    precision = 0
                    break
                case "gb":
                    precision = 1
                    break
                case "area":
                    precision = 2
                    break
                default:
                    throw new Error("Unrecognized value type: " + type)
            }
            let s = val.toFixed(precision)
            if (groupDelimiter === null) {
                return s
            }
            const ptIdx = s.indexOf(".")
            let fracPart
            if (ptIdx !== -1) {
                fracPart = s.slice(ptIdx)
            } else {
                fracPart = null
            }
            const intPart = s.slice(0, ptIdx !== -1 ? ptIdx : undefined)
            const groups = []
            for (let i = intPart.length; i > 0; i -= 3) {
                let j = i - 3
                if (j < 0) {
                    j = 0
                }
                groups.push(intPart.slice(j, i))
            }
            groups.reverse()
            s = groups.join(" ")
            if (fracPart !== null) {
                s += fracPart
            }
            return s
        },

        _OnShare() {
            const data = this.shareableData
            if (data === null) {
                return
            }
            this.shareDialog = true
        },

        _OnSent() {
            this.shareDialog = false
            this.$q.notify({
                message: "E-mail sent",
                icon: "send",
                color: "positive"
            })
        },

        _OnMapAreaUpdated(area) {
            this.mapArea = area
        },

        _OnPolygonChanged(points) {
            this.polygonPoints = points
        },

        _OnMapShown() {
            const map = this.$refs.map
            map.InvalidateSize()
            if (this.presetPolygonPoints !== null) {
                map.SetPoints(this.presetPolygonPoints)
                this.presetPolygonPoints = null
            }
        },

        _CameraProfileFilter(val, update) {
            if (val === '') {
                update(() => {
                    this.filteredCameraProfiles = this.cameraProfilesList
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.filteredCameraProfiles = this.cameraProfilesList.filter(
                    v => v.name.toLowerCase().indexOf(needle) !== -1)
                    .sort((p1, p2) => p1.name.localeCompare(p2.name))
            })
        }
    },

    mounted() {
        const q = this.$route.query
        if ("area" in q) {
            this.manualArea = q.area
        }
        if ("units" in q) {
            this.units = AreaUnits[q.units]
        }
        if ("gsd" in q) {
            this.gsd = q.gsd
        }
        if ("cameraProfile" in q && this.cameraProfiles !== null) {
            const id = Number(q.cameraProfile)
            if (!isNaN(id)) {
                this.cameraProfile = this.cameraProfiles.get(id) || null
            }
        }
        if ("customCamera" in q) {
            this.customCamera = q.customCamera === "true"
        }
        if (this.customCamera) {
            this.cameraProfile = null
            if ("imageWidth" in q) {
                this.imageWidth = Number(q.imageWidth)
            }
            if ("imageHeight" in q) {
                this.imageHeight = Number(q.imageHeight)
            }
        }
        if ("mapPoints" in q) {
            let mapPoints = null
            try {
                mapPoints = q.mapPoints.map(ParseLatLng)
            } catch (e) {
                console.log(e)
                this.$q.notify({
                    message: "Map points parsing failed",
                    icon: "error",
                    color: "negative"
                })
            }
            if (mapPoints) {
                this.isMapActive = true
                this.presetPolygonPoints = mapPoints
            }
        }
    }
}

</script>

<style scoped lang="less">
.input-field {
    width: 200px;
}
</style>